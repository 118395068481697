import React, { useState } from 'react';
import "./menu.css"

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, onClose }) => {
  return (
    <div className={`menu ${isOpen ? 'open' : ''}`}>
      <div className="nigga">
        <button className="closeButton" onClick={onClose}>
          Close
        </button>
        <div className='clusterI'>
          <a href="https://discord.gg/unityacademy  ">
            <img className="nigI more" src="images/discord.png" alt="" />
          </a>
          <a href="https://twitter.com/UnityAcademyInc">
            <img className="nigI fff mt-[-0.1rem] more " src="images/x.png" alt="" />
          </a>
          <a href="">
            <img className="nigI" src="" alt="" />
          </a>
        </div>
      </div>
      <a href="/dashboard">
        <div className='lin'>
          The Academy Pro
        </div>
      </a>
      <a href="/pricing" >
        <div className='lin'>
          Pricing
        </div>
      </a>
      <a href="/oraclealgo">
        <div className='lin'>
          OracleAlgo
        </div>
      </a>
      <a href="/recap">
        <div className='lin'>
          Trade Recaps
        </div>
      </a>
      <a href="/affiliate">
        <div className='lin'>
          Affiliate Program
        </div>
      </a>
      <a href="/team">
        <div className='lin'>
          Meet The Team
        </div>
      </a>
      <a href="/legal">
        <div className='lin'>
          Disclaimer
        </div>
      </a>
    </div>
  );
};

export default Menu;