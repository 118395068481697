import * as React from 'react'
import { useState } from 'react';
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Dropdown, DropdownDivider, Navbar } from 'flowbite-react';
import Menu from './menu.tsx';

function scrollToElement(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

function Nav() {
  const oracleAlgo = "images/oracle-algo.svg";
  // State to apply Navbar BG on hover
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav
      className={`ml-0 max-md:flex-col z-[1000] backdrop-filter backdrop-blur-120 fixed w-full px-8 mx-md:px-0 sm:px-20 pt-4 ${scrolling ? "bg-black py-4 top-0 mt-0" : "bg-transparent mt-4"
        }`}
    >
      {/* Logo */}
      <div className="flex items-center justify-between w-full max-md:gap-4">
        <Link to="/oraclealgo">
          <img
            src={oracleAlgo}
            alt="Unity Academy"
            width={253}
            className="cursor-pointer"
          />
        </Link>

        {/* Hamburger Button */}
        <button
          className="text-white max-md:block md:hidden"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle navigation"
        >
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-12 h-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-12 h-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>

        {/* Navigation Links - Mobile Dropdown */}
        <div
          className={`max-md:flex max-md:flex-col max-md:gap-y-4 max-md:items-start max-md:mt-0 md:flex md:flex-row md:gap-x-20 md:items-center md:mt-0 transition-all duration-300 ${isOpen ? "max-md:block absolute top-full right-0 left-0 w-full bg-black" : "max-md:hidden"
            }`}
        >
          <button
            className="hover:bg-transparent flex items-center h-full text-white font-semibold text-xl p-4"
            onClick={() => scrollToElement('features')}
          >
            Features
          </button>
          <button
            className="hover:bg-transparent flex items-center h-full text-white font-semibold text-xl p-4"
            onClick={() => scrollToElement('results')}
          >
            Results
          </button>
          <button
            className="hover:bg-transparent flex items-center h-full text-white font-semibold text-xl p-4"
            onClick={() => scrollToElement('pricing')}
          >
            Pricing
          </button>
          <a
            href="/"
            className="hover:bg-transparent flex items-center h-full text-white font-semibold text-xl p-4"
          >
            Unity Academy
          </a>
        </div>
      </div>
    </nav>

  );
}

export default Nav;