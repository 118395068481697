import * as React from "react";
import { useEffect } from "react";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.tsx";
import OracleAlgoPricing from "../components/OracleAlgoPricing.tsx";
import Oracle1 from "../components/Oracle1.tsx";
import Oracle2 from "../components/Oracle2.tsx";
import OracleTrustedBy from "../components/OracleTurstedBy.tsx";
import NavOracle from "../components/Navbar-oracle.tsx"
import FooterOracle from "../components/FooterOracle.tsx"

function Oracle() {
  useEffect(() => {
    // Change the body background color
    document.body.style.backgroundColor = "#F5F5F5"; // Black color

    // Cleanup to reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = ""; // Reset to default
    };
  }, []);
  return (
    <div className="h-screen">
      <NavOracle />
      <div className="flex flex-col items-center justify-center overflow-hidden">
        <Oracle1 />
        <Oracle2 />
      </div>
      <OracleTrustedBy />
      <div className="flex flex-col items-center justify-center mx-4 mt-4 py-20  bg-no-repeat bg-cover rounded-[35px] bg-center bg-no-repeat relative" style={{ backgroundImage: 'url(/images/pricing-plans-bg.svg)' }}>
        <OracleAlgoPricing />
        {/* <JoinCommunity /> */}
      </div>
      <FrequentlyAskedQuestions textColor="black" />
      <FooterOracle />
      <div className="h-12" />
    </div>
  );
}

export default Oracle;