import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.css";

function OracleTrustedBy() {
  const reviews = [
    {
      username: "r3ywtf",
      content: "This indicator is insane, it powers up my trading so much. The traders in this server are the most accurate I have ever seen and the knowledge that you can get here is infinite.",
      userImage: "/images/user-1.png"
    },
    {
      username: "alfa618",
      content: "OracleAlgo is the best free indicator I've ever seen. I am a beginner trader and don't really understand how to set up a trading strategy correctly, but this indicator and its guide really help me make decisions more easily.",
      userImage: "/images/user-2.png"
    },
    {
      username: "Gryphus987",
      content: "This indicator is one of the best I've seen as far as integrated. It simplifies adding confluence to trading ideas. The indicator does the heavy lifting, leaving you to analyze and execute. Awesome work.",
      userImage: "/images/user-3.png"
    },
    {
      username: "wolfiess",
      content: "Oracle is one of the best tools we can have in the market, the indicator is perfectly matched with time and quick market moves, the best thing to ever happened in my trading journey. Thanks for Caleb and his team for the great product, really proud to be part of Unity Academy.",
      userImage: "/images/user-4.png"
    },
    {
      username: "Maxouhell",
      content: "OracleAlgo is a real time saver. It does all the time-consuming part of TA. Simple settings and you have all the data you need to start. Then you add your things, and magic happens. It can save your ass by pointing on things that you sometimes don't check. Love it!",
      userImage: "/images/user-5.png"
    },
    {
      username: "kingsivaa",
      content: "OracleAlgo is the best indicator for the best community! OracleAlgo helps you understand the direction of the market and find your strategy starting from a solid and precise basis. I am really satisfied with this tool because since I've been using it my performance has definitely improved!",
      userImage: "/images/user-6.png"
    }
  ];

  const sliderSettings = {
    infinite: true,
    speed: 3000,
    arrows: false,
    draggable: true,
    autoplay: true,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-md:mb-8 relative bg-[#F5F5F5] rounded-3xl mx-4 py-24 max-md:py-4 bg-no-repeat bg-cover bg-center backdrop-blur-[136px] overflow-hidden" id="results">
      <h1 className="max-md:text-[30px] text-[60px] text-black font-semibold text-center mb-12 mt-4">Real Traders, Real Stories</h1>
      <Slider {...sliderSettings}>
        {reviews.map((review, index) => (
          <div key={index} className="flex flex-[31%] max-md:flex-[100%] flex-col gap-y-5 min-h-[179px] bg-[#fff] border border-[#DEDEDE] rounded-2xl py-5 px-6 mx-4">
            <div className="flex justify-between items-center mb-5">
              <div className="flex gap-3 items-center">
                <img src={review.userImage} alt="User" />
                <p className="text-lg font-semibold text-black">{review.username}</p>
              </div>
              <ul className="flex gap-1 items-center">
                {[...Array(5)].map((_, i) => (
                  <li key={i}><img src="/images/star.svg" alt="Star" /></li>
                ))}
              </ul>
            </div>
            <p className="text-sm font-regular text-black">{review.content}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OracleTrustedBy;