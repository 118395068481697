import * as React from "react";
import { Dispatch, SetStateAction, FC, useContext, useEffect, useState } from "react";
import Landing from "../components/Landing.tsx";
import OracleAlgo from "../components/OracleAlgo.tsx";
import Footer from '../components/Footer.tsx'
import TrustedBy from "../components/TrustedBy.tsx";
import FeaturedCourses from "../components/FeaturedCourses.tsx";
import Onboarding from "../components/Onboarding.tsx";
import UnityAcademy from "../components/UnityAcademy.tsx";
import JoinCommunity from "../components/JoinCommunity.tsx";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions.tsx";
import OracleAlgoPricing from "../components/OracleAlgoPricing.tsx";
import TradingCoursePricing from "../components/TradingCoursePricing.tsx";
import OraclePerks from "../components/OraclePerks.tsx";
import JoinUnity from "../components/JoinUnity.tsx";
import Pricing from "../components/Profits.tsx";
import TradingMastery1 from "../components/TradingMastery1.tsx";
import TradingMastery2 from "../components/TradingMastery2.tsx";
import Oracle1 from "../components/Oracle1.tsx";
import { ViewContext } from "../constants/context.ts";
import { slideDown } from "../constants/framer.config.ts";
import Oracle2 from "../components/Oracle2.tsx";
import Oracle3 from "../components/Oracle3.tsx";
import Oracle4 from "../components/Oracle4.tsx";
import { motion } from "framer-motion";
import OracleTrustedBy from "../components/OracleTurstedBy.tsx";
import Nav from "../components/Navbar.tsx"

function Home() {
  const { showView } = useContext(ViewContext);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true);
    }, 1000);
  }, []);

  return (
    <div className="h-screen">


      {/* <div onClick={(e) => { setShowPopup(false); }} className={`${showPopup ? "fixed" : "hidden"} z-[100] flex items-center justify-center h-screen w-screen text-white`}>


                <div onClick={(e) => { e.stopPropagation(); }} id="popup" className="flex flex-col rounded-[40px] border items-center justify-center backdrop-blur-[30px] px-20 max-sm:px-10 py-12 max-sm:py-6 sm:[85vw] md:w-[70vw] lg:w-[60vw] max-w-[870px] fixed max-md:scale-75">
                    <div className="flex items-center justify-between w-full">
                        <img className="h-[80px] max-md:h-[60px]" src="/images/popup logo.png" />

                        <img onClick={() => setShowPopup(false)} className="h-[36px] max-md:h-[32px] mt-4 max-md:mt-2 cursor-pointer" src="/images/popup close.png" />
                    </div>

                    <div className="flex justify-between w-full mt-10 max-sm:flex-col max-sm:gap-3 max-sm:items-center">
                        <span className="text-2xl font-bold">
                            Become  A Member Today
                        </span>

                        <div>
                            <span className="text-2xl font-bold">
                                $29.99
                            </span>

                            <span className="text-xl ml-1 font-normal text-[#F3F3F3] opacity-80">
                                /month
                            </span>
                        </div>
                    </div>

                    <div className="h-[1.5px] bg-[#FF9555] w-full my-3 max-sm:my-5"></div>

                    <div className="w-full font-medium text-xl mt-3 max-sm:font-normal">
                        Plans includes:

                        <div className="flex flex-wrap mt-3">
                            <span className="w-1/2 max-sm:w-full flex py-3">
                                <img className="h-[18px] mr-3 mt-2" src="/images/popup check.png" />
                                VIP Crypto Signals
                            </span>

                            <span className="w-1/2 max-sm:w-full flex py-3">
                                <img className="h-[18px] mr-3 mt-2" src="/images/popup check.png" />
                                Angel Investment Deals
                            </span>

                            <span className="w-1/2 max-sm:w-full flex py-3">
                                <img className="h-[18px] mr-3 mt-2" src="/images/popup check.png" />
                                Educational Platform
                            </span>

                            <span className="w-1/2 max-sm:w-full flex py-3">
                                <img className="h-[18px] mr-3 mt-2" src="/images/popup check.png" />
                                Access to VIP Community
                            </span>
                        </div>
                    </div>
                    <div className="xx flex gap-5 max-md:flex-col  max-md:mt-[-2rem]">
                        <a href="https://discord.com/channels/1087843967573438504/1243516070204280894" target="_blank">
                            <div className="bg-[#FCFAF7E5] w-[300px] cursor-pointer text-[#252525] px-14 py-4 rounded-[22px] font-black text-[1.5rem] max-sm:text-[1.3rem] mt-12">
                                Pay With Crypto
                            </div></a>
                        <a href="https://whop.com/unity-academy/" target="_blank">
                            <div className="bg-[#FCFAF7E5] max-md:mt-[0.5rem] w-[300px] text-center cursor-pointer text-[#252525] px-14 py-4 rounded-[22px] font-black text-[1.5rem] max-sm:text-[1.3rem] mt-12">
                                Pay With Card
                            </div></a>
                    </div>
                </div>

            </div> */}


      <div className="flex flex-col items-center justify-center" >

        <Landing />

        <div className="mt-[8rem]"></div>

        <TradingMastery1 />
        <div className="mt-[8rem]"></div>
        <div className="mt-[8rem]"></div>
        <div className="flex flex-col items-center justify-center">

          <TradingMastery2 />

          <FeaturedCourses />
        </div>
        <div className="mt-[8rem]"></div>

        <TradingCoursePricing />
        <div className="mt-[8rem]"></div>
        <Onboarding />

        <UnityAcademy />
        <JoinUnity />


      </div>

      <TrustedBy set1="true" />


      <FrequentlyAskedQuestions textColor="white" />
      <div className="flex flex-col items-center justify-center">
        <JoinCommunity />
      </div>
      <Footer />

    </div>
  );
}

export default Home;