import * as React from 'react';

function Footer() {
  return (
    <div className="items-center bg-black flex flex-col justify-center px-16 py-12 border-t-white border-t border-solid max-md:px-5">
      <div className="relative flex w-full flex-col items-stretch mt-4.5 mb-1.5">

        <div className="justify-between flex w-full gap-5 items-start max-md:flex-col">
          <div className="items-stretch flex basis-[0%] flex-col">
            <div className='flex items-center justify-start gap-5'>
              <img
                srcSet="images/unity.png"
                className="scale-110 object-contain object-center w-[150px] ml-[-0.5rem] overflow-hidden max-w-full self-start"
              />
              <img
                srcSet="images/log1.webp"
                className="scale-200 object-contain object-center w-[550px] overflow-hidden max-w-full self-start"
              /></div>
            <div className="text-neutral-400 text-sm mb-2 leading-5 font-bold whitespace-nowrap mt-6 self-start">
              Unity Academy
            </div>
            <div className="items-center flex  mt-4 pr-20 max-md:pr-5">
              <a href="https://discord.gg/unityacademy" className='w-[90px]'>
                <img
                  src="images/discord.png"
                  className="aspect-square cursor-pointer object-contain overflow-hidden shrink-0 max-w-full"
                />
              </a>
              <a href="https://twitter.com/UnityAcademyInc" className='w-[30px] ml-[-2.5rem]'>
                <img
                  src="images/x.png"
                  className="aspect-square cursor-pointer object-contain object-center overflow-hidden shrink-0 max-w-full"
                />
              </a>
            </div>
          </div>

          <div className="self-stretch max-md:max-w-full">
            <div className="gap-5 grid grid-flow-row-dense grid-cols-4 max-md:grid-cols-2 max-md:items-stretch max-md:gap-0">

              <div className="flex flex-col items-stretch w-[35%] max-md:w-full max-md:ml-0">
                <div className="items-stretch flex grow flex-col pb-3.5 max-md:mt-10">
                  <div className="text-neutral-500 text-xs font-medium leading-4 tracking-wider uppercase whitespace-nowrap">
                    Products
                  </div>

                  <a href="#oracleAlgo">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      Oracle Algo
                    </div>
                  </a>
                  <a href="#oracleAlgoPricing">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      Oracle Algo Pricing
                    </div>
                  </a>
                  <a href="#education">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      UnityAcademy Pro
                    </div>
                  </a>

                  <a href="#pricing">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      UnityAcademyPro Pricing
                    </div>
                  </a>
                </div>
              </div>

              <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                <div className="items-stretch flex grow flex-col pb-12 max-md:mt-10">

                  <div className="text-neutral-500 text-xs font-medium leading-4 tracking-wider uppercase whitespace-nowrap">
                    Resources
                  </div>

                  <a href="https://discord.gg/unityacademy">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      Community
                    </div>
                  </a>
                  <a href="/affiliate">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      Affiliate Program
                    </div>
                  </a>
                  <a href="https://discord.gg/unityacademy">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4 mb-11 max-md:mb-10">
                      Contact
                    </div>
                  </a>
                </div>
              </div>

              <div className="flex flex-col items-stretch w-[21%] ml-5 max-md:w-full max-md:ml-0">
                <div className="items-stretch flex grow flex-col pb-12 max-md:mt-10">

                  <div className="text-neutral-500 text-xs font-medium leading-4 tracking-wider uppercase whitespace-nowrap">
                    Company
                  </div>

                  <a href="/legal">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      Terms of Use
                    </div>
                  </a>
                  <a href="/legal">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-4">
                      Disclaimer
                    </div>
                  </a>

                </div>
              </div>

              <div className="flex flex-col items-stretch w-[18%] ml-5 max-md:w-full max-md:ml-0">
                <div className="items-stretch flex grow flex-col pb-12 max-md:mt-10">
                  <div className="text-neutral-500 text-xs font-medium leading-4 tracking-wider uppercase whitespace-nowrap">
                    My Account
                  </div>
                  <a href="https://unityacademy.io/dashboard">
                    <div className="text-zinc-300 text-xs leading-5 whitespace-nowrap mt-5 mb-24 max-md:mb-10">
                      Dashboard
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>

        <hr className="w-full mx-auto h-[2px] bg-gray-700 mt-4 mb-4 border-0 rounded"></hr>

        <div id="disclaimer" className="text-white text-xl font-semibold mt-6 max-md:max-w-full">
          Disclaimer
        </div>
        <div className="text-zinc-400 text-xs leading-5 underline mt-4 max-md:max-w-full">
          Trading is risky & most day traders lose money. This site & the
          products & services Unity Academy offers are for informational &
          educational purposes only. All content is to be considered
          hypothetical, selected after the fact, in order to demonstrate our
          product and should not be construed as financial advice. Decisions to
          buy, sell, hold or trade in securities, commodities and other
          investments involve risk and are best made based on the advice of
          qualified financial professionals. Past performance does not guarantee
          future results.
          <br />
          Hypothetical or Simulated performance results have certain
          limitations, unlike an actual performance record, simulated results do
          not represent actual trading. Also, since the trades have not been
          executed, the results may have under-or-over compensated for the
          impact, if any, of certain market factors, such as lack of liquidity.
          Simulated trading programs in general are also subject to the fact
          that they are designed with the benefit of hindsight. No
          representation is being made that any account will or is likely to
          achieve profit or losses similar to those shown.
          <br />
          Testimonials appearing on this website may not be representative of
          other clients or customers and is not a guarantee of future
          performance or success.
          <br />
          As a provider of technical analysis tools for charting platforms, we
          do not have access to the personal trading accounts or brokerage
          statements of our customers. As a result, we have no reason to believe
          our customers perform better or worse than traders as a whole based on
          any content or tool we provide.
          <br />
          Charts used on this site are by TradingView in which our tools are
          built on. TradingView® is a registered trademark of TradingView, Inc.
          <br />
          This does not represent our full Disclaimer. Please read our full
          disclaimer.
          <br />
          © 2023 Unity Academy Global, LLC.
        </div>
      </div>
    </div>
  );
}

export default Footer;