import * as React from 'react';
import { ViewContext } from "../constants/context.ts";
import { slideUp, imageLoadAnimation } from "../constants/framer.config.ts";
import { motion } from "framer-motion";
import { Variants } from "framer-motion";
import { Dispatch, SetStateAction, FC, useContext } from "react";

function Onboarding() {
  const { showView } = useContext(ViewContext);
  return (
    <motion.div {...slideUp(showView)}>
      <div className="remove flex flex-col items-center mt-10">
        <div className="text-white text-4xl max-md:text-2xl font-semibold whitespace-nowrap max-md:max-w-full">
          Become an expert trader today
        </div>
        <div className="self-stretch w-full mt-12 max-md:max-w-full max-md:mt-0">
          <div className="gap-5 justify-center items-center flex flex-wrap max-md:gap-6">
            <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
              <div className="border bg-custom-grey shadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                <div className="text-neutral-800 text-2xl font-semibold self-center justify-center items-center align-center m-auto flex w-20 h-20 rounded-full bg-orange-50">
                  1
                </div>
                <div className="text-white text-center text-2xl self-stretch mt-10 max-md:mt-10">
                  Create Your Discord Account
                </div>
                <div className="text-stone-300 text-center text-lg font-medium self-stretch mt-11 max-md:mt-10">
                  Simply setup a Discord account or use your existing account to
                  join Unity Academy.
                </div>
                <a href="https://discord.gg/unityacademy" className="butt text-neutral-800 cursor-pointer text-center text-lg font-extrabold bg-orange-50 justify-center items-stretch mt-10 px-14 py-3 rounded-[30px] max-md:mt-10 max-md:px-5">
                  Join Unity Academy
                </a>
              </div>
            </div>

            <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
              <div className="shadow-sm bg-orange-50 border bg-custom-greyshadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                <div className="bg-neutral-800 text-white text-2xl font-semibold self-center justify-center items-center align-center m-auto flex w-20 h-20 rounded-full">
                  2
                </div>
                <div className="text-black text-center text-2xl self-stretch mt-10 max-md:mt-10">
                  Become a member for free
                </div>
                <div className="text-neutral-700 text-center text-lg font-medium self-stretch mt-10 max-md:mt-10">
                  Open your account with any reputable cryptocurrency exchange
                  such as Binance, ByBit or Mexc etc.
                </div>
                <a href="https://bingx.com/partner/UnityAcademy/4baHPy" className="butt butt2 text-white cursor-pointer bg-neutral-800 text-center text-lg font-extrabold  justify-center items-stretch mt-12 px-14 py-3 rounded-[30px] max-md:mt-10 max-md:px-5">
                  Make your Account
                </a>
              </div>
            </div>

            <div className="flex flex-col items-stretch min-w-[360px] max-w-[410px] w-[33%] max-md:w-[70%]">
              <div className="border bg-custom-grey shadow-sm flex grow flex-col items-center w-full px-10 py-9 rounded-[39px] border-solid border-stone-50 max-md:max-w-full max-md:mt-10 max-md:px-5">
                <div className="text-neutral-800 text-2xl font-semibold self-center justify-center items-center align-center m-auto flex w-20 h-20 rounded-full bg-orange-50">
                  3
                </div>
                <div className="text-white text-center text-2xl self-stretch mt-10 max-md:mt-10">
                  Master Trading
                </div>
                <div className="text-stone-300 text-center text-lg font-medium self-stretch mt-10 max-md:mt-10">
                  Access expert training, mentoring, and winning trade signals to
                  become a profitable trader.
                </div>
                <a href="https://discord.gg/unityacademy" className="butt text-neutral-800 cursor-pointer text-center text-lg font-extrabold bg-orange-50 justify-center items-stretch mt-12 px-14 py-3 rounded-[30px] max-md:mt-10 max-md:px-5">
                  Become a Member
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div >
  );
}

export default Onboarding;