
var data = {
  1: {
    heading: "Trading Introduction",
    body: "Understand all of the key fundamental foundations of the trading basics + much more with our Trading Introduction Course.",
    highlights: [
      "Beginner",
      "24 Lessons"
    ],
    length: "24+ Hours",
    difficulty: "Easy",
    link: "https://course1.unityacademy.io/",
    img: "images/sh1.webp",
    lessons: [
      "Crypto Introduction",
      "Crypto Markets",
      "Forex Markets",
      "Stock Intro",
      "Stock Markets",
      "Bitcoin Intro",
      "Blockchain Explained",
      "Exchanges Intro",
      "CEX vs DEX",
      "Crypto Wallets",
      "Researching Crypto",
      "Trading Safety",
      "Finance Budget",
      "Trading Taxes",
      "Portfolio Management",
      "Common Mistakes",
      "Airdrop Farming",
      "How To Trade",
      "Technical Analysis",
      "Market/Limit Orders",
      "Cross vs Isolated Margin",
      "Trading View Guide",
      "Meta Trader 5 Guide",
    ]
  },

  2: {
    heading: "Start Trading",
    body: "Unlock access to key fundamental trading insights which will help you understand key psychological confluences as well as industry secrets with our Start Trading Course.",
    highlights: [
      "Intermediate",
      "28 Lessons"
    ],
    length: "48+ Hours",
    difficulty: "Intermediate",
    link: "https://course2.unityacademy.io/",
    img: "images/sh1.webp",

    lessons: [
      "Risk Management",
      "Money Management",
      "Trading Intuition",
      "Longevity Mindset",
      "Day Trading Advice",
      "Swing Vs Scalps",
      "No Weekend Trading",
      "Time Zones / Frames",
      "Analyzing Charts",
      "Chart Patterns",
      "Trend Lines",
      "Order Blocks",
      "Fair Value Gaps",
      "Points of Interests",
      "Understanding Liquidity",
      "Candlesticks",
      "Optimal Entries",
      "Price Ranges",
      "Price Targets",
      "Risk : Reward",
      "Size & Stops",
      "Trading Plan",
      "Trading Rules",
      "Trading Strategy",
      "Trade Idea Examples",
      "Daily Habits & Performance Tracker",
      "Trading Indicators",
      "Trading Journal",
      "Exiting Strategy",
    ]
  },

  3: {
    heading: "Master Trading",
    body: "Unlock access to high win rate strategies, theory concepts, key trading edges, psychological advantages + much more with our Master Trading Course.",
    highlights: [
      "Advanced",
      "28 Lessons"
    ],
    length: "72+ Hours",
    difficulty: "Advanced",
    link: "https://course3.unityacademy.io/",
    img: "images/sh1.webp",
    lessons: [
      "Market Psychology",
      "Trade Psychology",
      "Fundamentals",
      "TA Breakdown",
      "Analysis Mistakes",

      "Forward Testing",
      "Market Sentiment",
      "Trading News",
      "Market News Affects",
      "Stoploss Hunts",
      "VIX Vs DXY",
      "Price Imbalances",
      "Market Structure",
      "Break Of Structure",
      "Change Of Character",
      "Inducement",
      "Trading Confirmations",
      "On Chain Analysis",
      "Range Bound Trading",
      "Supply & Demand",
      "Price Action Trading",
      "Hybrid Trading",
      "Reading Bookmaps",
      "Elliott Wave Theory",
      "Fibonacci Theory",
      "ICT Concepts",
      "Smart Money Concepts",
    ]
  },

  4: {
    heading: "Self Improvement",
    body: "Unlock access to self mastery, nutrition, mind conditioning, daily habits, stoicism, self control, fitness training + much more with our Self Improvement Course.",
    highlights: [
      "Mastery",
      "50 Lessons"
    ],
    length: "96+ Hours",
    difficulty: "Mastery",
    link: "https://course4.unityacademy.io/",
    img: "images/sh1.webp",
    lessons: [
      "Healthy Lifestyle",
      "Balanced Life",
      "Positive Changes",
      "Mindset Types",
      "Mind Conditioning",
      "Daily Habits",
      "Sleep",
      "Stoicism",
      "Best Books",
      "Meditation",
      "Skill Development",
      "Circadian Rhythm",
      "Self Coaching",
      "Self Control",
      "Finding Purpose",
      "Gratitude",
      "Exercise Importance",
      "Beginning Routine",
      "Best Fitness Tools",
      "Calorie Intake",
      "Metabolism",
      "Nutrition Workout",
      "Practical Tips For Weight Loss",
      "Bulking",
      "Cutting",
      "Fasting",
      "Stretching",
      "Weight Training Exercises",
      "Cardio",
      "Calisthenics",
      "Nutrition Introduction",
      "Food benefits",
      "Proteins",
      "Carbohydrate Mystery ",
      "Starchy Foods",
      "Olive Oils",
      "Calories",
      "Vitamins",
      "Fibers",
      "Iron",
      "Magnesium",
      "Supplements",
      "Nutrition Workout",
      "Cancer Defensive Foods",
      "Micronutrients",
      "Diet Creation",
      "Weekly Meal Plans",
      "Anti-Inflammatory Diet",
      "Pre-Workout Meal",
      "Post-Workout Meal",

    ]
  }
}

export default data;