import * as React from 'react';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="flex items-center justify-between p-6 border bg-white border-[#DEDEDE] rounded-2xl mx-32 mb-12 max-md:flex-col max-md:mx-4 max-md:items-start">
      <a href="/oraclealgo"><img src="/images/footer-oracle-algo.svg" alt="Footer Logo" /></a>
      <div className='flex gap-x-14 items-center flex-wrap max-md:gap-0 max-md:gap-y-3 max-md:mt-4'>
        <Link className='hover:bg-transparent flex items-center h-full text-black text-opacity-50 font-medium max-md:flex-[50%] text-lg max-md:text-sm' to="/legal">Terms & Conditions</Link>
        <Link className='hover:bg-transparent flex items-center h-full text-black text-opacity-50 font-medium text-lg max-md:text-sm max-md:flex-[50%]' to="/legal">Privacy Policy</Link>
        <span className='hover:bg-transparent flex items-center h-full text-black text-opacity-50 font-medium text-lg max-md:text-sm max-md:flex-[100%]'>© Copyright UnityAcademy 2024</span>
      </div>
    </div>
  );
}

export default Footer;