import * as React from 'react'
import { useState } from 'react';
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Dropdown, DropdownDivider, Navbar } from 'flowbite-react';
import Menu from './menu.tsx';

function Nav() {
  const location = useLocation();
  const defaultLogo = "images/logo.webp";
  const oracleAlgo = "images/oracle-algo.svg";
  const logo = location.pathname === "/oraclealgo" ? oracleAlgo : defaultLogo;

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // State to apply Navar BG on hover
  const [scrolling, setScrolling] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Navbar className={`nav bg-transparent mt-4 fixed z-[1000] backdrop-filter backdrop-blur-1200 m-0 w-full max-md:flex-row ${scrolling ? "bg-black shadow-lg" : "bg-transparent"}`}>
      <Navbar.Brand className='max-md:w-[150px] mt-1' href="/">
        <img src={logo} className="mr-3 h-12" alt="Flowbite React Logo" />
      </Navbar.Brand>
      <button className='text-white hidden brodie max-md:flex mr-[1rem] item-center justify-center gap-3 cl cursor-pointer mx-auto text-center text-md max-md:w-[100px] max-md:mt-2 font-medium  bg-[#4F4F4F] self-stretch items-center px-5 py-2.5 rounded-[30px]  border-[2px] border-transparent' onClick={handleMenuToggle}>
        <img className="w-[35px]" src="images/m5.webp" alt="" />
        <p className="max-md:hidden">Open Menu</p> </button>
      <Menu isOpen={isMenuOpen} onClose={handleMenuClose} />
      <Navbar.Toggle className='focus:bg-transparent max-md:hidden focus:border-0 bg-none hover:bg-none hover:border-0 focus:ring-0 pr-0' />
      <Navbar.Collapse className='duration-1000 mr-5 '>
        <Navbar.Link className='hover:bg-transparent flex items-center h-full text-white font-semibold text-base' href="#">Home</Navbar.Link>


        {/* <Dropdown className="duration-1000" arrowIcon={false} inline label={<Navbar.Link className='flex hover:bg-transparent items-center max-md:border-0 h-full text-white font-semibold text-base' >Features <img className='ml-2 mt-1 scale-75' src='images/arrow-down.png' /></Navbar.Link>}>

            <a href="#oracleAlgo">
              <Dropdown.Item className='py-3'>OracleAlgo</Dropdown.Item>
            </a>
            <a href="#UnityAcademyPro">
              <Dropdown.Item className='py-3'>UnityAcademy Pro</Dropdown.Item>
            </a>
            <a href="/affiliate">
              <Dropdown.Item className='py-3'> Affiliate Program</Dropdown.Item>
            </a>
            <a href="/team">
              <Dropdown.Item className='py-3'>Meet The Team</Dropdown.Item>
            </a>
            <a href="#disclaimer">
              <Dropdown.Item className='py-3'>Privacy Policy</Dropdown.Item>
            </a>
            <DropdownDivider />
            <Dropdown.Item className='flex gap-5'>
              <a href="https://discord.com/invite/unityacademyinc">
                <img className="w-[30px] h-[30px] opacity-70 more" src="https://media.discordapp.net/attachments/874259441384574976/1197837272578523156/discord-icon-43736.png?ex=65bcb7e4&is=65aa42e4&hm=28d7073a808eec4b290015ef3056fe99b25a368fe55554a999defe0ce3fb7e51&=&format=webp&quality=lossless&width=576&height=576" alt="" />
              </a>
              <img className="w-[20px] h-[20px] opacity-70 more" src="https://media.discordapp.net/attachments/874259441384574976/1197847985644650546/x-social-media-black-icon.png?ex=65bcc1de&is=65aa4cde&hm=85e743bcb503fd2aa88cc0715ee587867b12d001b0cf51bcad29d03c0a270cc5&=&format=webp&quality=lossless&width=576&height=576" alt="" />
            </Dropdown.Item>
          </Dropdown> */}


        <Navbar.Link className='hover:bg-transparent flex items-center h-full text-white font-semibold text-base' href="/pricing">Pricing</Navbar.Link>
        <Navbar.Link className='hover:bg-transparent flex items-center h-full text-white font-semibold text-base' href="#education">Education</Navbar.Link>

        <Navbar.Link className='hover:bg-transparent flex items-center h-full text-white font-semibold text-base' href="/oraclealgo">OracleAlgo</Navbar.Link>

        <Navbar.Link className='hover:bg-transparent flex items-center h-full text-white font-semibold text-base' href="/dashboard" target='_blank'>Dashboard</Navbar.Link>
        <button className='text-white flex item-center gap-3 cl cursor-pointer mx-auto text-center text-md max-md:w-[150px] max-md:mt-2 font-medium self-stretch justify-center items-center px-5 py-2.5 rounded-[30px] max-md:px-5 border-[2px] border-transparent ' onClick={handleMenuToggle}>
          <img className="w-[35px]" src="images/m5.webp" alt="" />
          Open Menu</button>
        <Menu isOpen={isMenuOpen} onClose={handleMenuClose} />
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Nav;